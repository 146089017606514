@import "../../../../constants.scss";

.add-edit-company-modal-container {  
  .modal-header {
    background: linear-gradient(135deg, $purple, #230f23);
    color: white;
  }

  .img-container:before {
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    top:0;left:0;right:0;
    background-color:rgba(0,0,0,0);
  }

  .img-container:hover::before {
    background-color:rgba(0,0,0,0.5);
  }

  .img-container button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity:0;
  } 

  .img-container:hover button {   
    opacity: 1;
  }

  .close {
    color: white;
  }

  .nav-link.active {
    border: none;
    border-bottom: 2px solid $purple !important;
    font-weight: bold;
    color: black;
  }

  .nav-link {
    color: black;
  }
}