@import "../../../constants.scss";

.projects-tab {
   .table-striped>tbody>tr:nth-of-type(odd){
      background-color: #f1f0fe;
   }
   
   .table-image td,th {
      vertical-align: middle;
   }
   
   .w-10 {
      width: 10%;
      height: 10%;
   }
  
   .purple-card-header {
     background: linear-gradient(135deg, $purple, #230f23);
     color: white;
   }
  
   .br-20 {
     border-radius: 20px !important;
   }
}
