@import "../../../constants.scss";

.purple-button {
    background-color: $purple !important;
    color: white !important;
}

.gray-button {
    background-color: lightgray !important;
    color: $purple !important;
}

/* TODO: remove the radius css and use bootstrap classes instead */
.round-3-button {
    border-top-right-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}
