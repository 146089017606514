@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import "./constants.scss";


.bg-purple {
  background-color: $purple !important;
}

.purple-color {
  color: $purple !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border-purple {
  border-bottom: 1px solid $purple !important;
}

.h-130 {
  height: 130px;
}

.w-260 {
  width: 260px;
}

.full-height {
  height: 92vh;
}

._loading_overlay_wrapper {
  height: 80vh;
}

.datepicker-control-section {
  max-width: 246px;
  margin: 30px auto;
  padding-top: 50px;
}

// pagination css
.pag-button {
  background: white;
  border: solid lightgray 1px;
  color: $purple;
}

.pag-button.disabled {
  background-color: lightgray;
  color: black;
}

.form-control:disabled {
  background-color: lightgray !important;
}

.w-15 {
  width: 15%;
  height: 15%;
}

.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 40px;
  bottom: 55px;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}


.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 10px;
  color: #fff;
  background-color: $purple;
  border: none;
  position: fixed;
  bottom: 25px;
  z-index: 9999;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.6s ease-in-out;
}

.app-chatbot-button:hover {
  transform: scale(1.1);
}

.loaderHeight {
  height: 100vh;
}

.upload-drive-label {
  margin-right: 10px;
}