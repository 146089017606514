.company-container {
   .table-striped>tbody>tr:nth-of-type(odd){
      background-color: #f1f0fe;
   }
   
   .table-image td,th {
      vertical-align: middle;
   }
   
   .w-10 {
      width: 10%;
      height: 10%;
   }
}
