@import "../../../constants.scss";

.attendance-container {
  min-height: 600px;
  max-width: 600px;
}

.b-card:hover {
  border: 2px solid $purple !important;
}
